<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer
        @save="save"
        @cancel="cancel"
        :loading="isLoading"
        :dirty="isDirty"
      >
        <settings v-model="obModel" :fix-default="fixDefault" />
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Mixins, Component, PropSync, Prop } from "vue-property-decorator";
import PricelistsMixin from "@/modules/pricelists/mixins/PricelistsMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/pricelists/components/tabs/Settings.vue";

@Component({
  components: {
    SheetDrawer,
    Settings,
  },
})
export default class PricelistsForm extends Mixins(PricelistsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  @Prop(Boolean) readonly fixDefault!: boolean;
}
</script>
