import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { PriceList, PriceListCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<PriceList, PriceListCollection>
>;

@Component
export default class PricelistsMixin extends Mixins(TypedModelMixin) {
  obCollection = new PriceListCollection();
  obModelClass = PriceList;
  obCollectionClass = PriceListCollection;
  sRoutePath = "/pricelists";

  created() {
    this.onCreated();
  }
}
